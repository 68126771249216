import * as React from 'react';
import {useEffect, useState, useCallback} from 'react';
import Grid from "@mui/material/Grid";
import {
    Alert,
    Breadcrumbs,
    Checkbox,
    FormControlLabel,
    InputLabel,
    NativeSelect,
    Tooltip,
    Card,
    CardContent,
    Stack,
    IconButton,
    Switch
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {ym_hit} from "../../../utils/yandex";
import { useNotification } from '../../../context/NotificationContext';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TranslateIcon from '@mui/icons-material/Translate';
import BuildIcon from '@mui/icons-material/Build';
import InfoIcon from '@mui/icons-material/Info';
import CustomSelect from '../../../components/CustomSelect';

const getChannelData = async () => {
    const response = await Requester.get(`channel/edit/`)
    const channel = await response.json()
    console.log(channel)
    return channel
}

export default function ChannelForm() {
    const { showNotification } = useNotification();
    const [channelData, setChannelData] = useState(null);
    const [state, setState] = useState({
        loading: false
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getChannelData();
                setChannelData(data);
            } catch (error) {
                console.error('Error fetching channel data:', error);
                showNotification('Ошибка при загрузке данных канала', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        };
        fetchData();
    }, [showNotification]);

    const saveChanges = useCallback(async (fieldName, value) => {
        if (state.loading) return;
        
        setState({ ...state, loading: true });
        
        const dataToSend = {
            ...channelData,
            [fieldName]: value
        };

        try {
            const response = await Requester.post(`channel/edit/`, dataToSend);
            if (response.status === 200) {
                const data = await getChannelData();
                setChannelData(data);
                showNotification('Настройки успешно сохранены', {
                    type: 'success',
                    autoHideDuration: 3000
                });
            } else if (response.status === 400) {
                const data = await response.json();
                console.log(data);
                showNotification('Ошибка при сохранении настроек', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        } catch (e) {
            console.log(e);
            showNotification('Ошибка при сохранении настроек', {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setState({ ...state, loading: false });
        }
    }, [state, channelData, showNotification]);

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        saveChanges(name, newValue);
    };

    ym_hit('/dashboard/channel-edit');

    if (!channelData) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
                    <Typography variant="h6" color="text.secondary">Loading...</Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link 
                            underline="hover" 
                            color="inherit" 
                            to={"/dashboard"} 
                            component={RouterLink}
                            sx={{ 
                                '&:hover': { color: 'primary.main' }
                            }}
                        >
                            Home
                        </Link>
                        <Typography color="primary">Настройки канала</Typography>
                    </Breadcrumbs>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <SettingsIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                        <Typography variant="h4" fontWeight="500">
                            Настройки канала
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card 
                            elevation={0}
                            sx={{ 
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                                overflow: 'visible'
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {/* Basic Settings Section */}
                                <Box sx={{ mb: 5 }}>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <SettingsIcon sx={{ color: 'primary.main' }} />
                                        Основные настройки
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={channelData.name}
                                                id="name"
                                                disabled={true}
                                                autoComplete="off"
                                                label="Название канала"
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={channelData.twitch_id}
                                                disabled={true}
                                                id="twitch_id"
                                                label="Twitch ID"
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        
                                        {channelData.is_bot_banned && (
                                            <Grid item xs={12}>
                                                <Alert 
                                                    severity="error"
                                                    sx={{
                                                        borderRadius: 2,
                                                    }}
                                                >
                                                    Бот был забанен на канале, если вы уже разбанили его, достаточно просто активировать бота
                                                </Alert>
                                            </Grid>
                                        )}
                                        
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            name="is_active" 
                                                            checked={channelData.is_active}
                                                            onChange={handleChange}
                                                            disabled={state.loading}
                                                        />
                                                    }
                                                    label={channelData.is_active ? "Бот активен" : "Бот отключен"}
                                                />
                                                <Typography 
                                                    variant="body2" 
                                                    color={channelData.is_active ? "success.main" : "text.secondary"}
                                                >
                                                    {!channelData.is_active && "Включите бота, чтобы он начал работать"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider sx={{ my: 4 }} />

                                {/* Currency Converter Section */}
                                <Box sx={{ mb: 5 }}>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <CurrencyExchangeIcon sx={{ color: 'primary.main' }} />
                                        Конвертер валют
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Бот будет автоматически находить в чате сообщения с валютой и конвертировать их в дефолтную валюту канала">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="enable_rate_finder"
                                                            checked={channelData.enable_rate_finder}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label="Активировать автоматическую конвертацию"
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CustomSelect
                                                value={channelData.channel_currency}
                                                options={channelData.currencies}
                                                onChange={(value) => handleChange({ target: { name: 'channel_currency', value } })}
                                                disabled={state.loading}
                                                label="Валюта канала"
                                                placeholder="Выберите валюту"
                                                searchPlaceholder="Поиск валюты..."
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider sx={{ my: 4 }} />

                                {/* Translator Section */}
                                <Box sx={{ mb: 5 }}>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TranslateIcon sx={{ color: 'primary.main' }} />
                                        Переводчик
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Бот автоматически будет переводить сообщения с различных языков на русский">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="translation_enabled"
                                                            checked={channelData.translation_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label="Переводить сообщения в чате"
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Например Ghbdtn vbh -> Привет мир">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="keyboard_translation_enabled"
                                                            checked={channelData.keyboard_translation_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label="Переводить неправильную раскладку"
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider sx={{ my: 4 }} />

                                {/* Utilities Section */}
                                <Box>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <BuildIcon sx={{ color: 'primary.main' }} />
                                        Утилиты
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Активирует команду !топдня и !топ">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="top_enabled"
                                                            checked={channelData.top_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label="Включить команды топа"
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title="Активирует команду !shazam для определения играющей музыки">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="is_shazam_enabled"
                                                            checked={channelData.is_shazam_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label="Включить Shazam"
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}