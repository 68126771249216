import Requester from '../utils/requester';

export async function fetchSubscriptionPlans() {
  try {
    const response = await Requester.get('subscriptions/types/list/');
    if (!response.ok) {
      throw new Error('Failed to fetch subscription plans');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching subscription plans:', error);
    return [];
  }
}

// Fallback data in case API is not available
export const defaultSubscriptionPlans = [
  {
    name: "Решил попробовать",
    price: "300р/месяц",
    features: ["Все функции бесплатного доступа", "300 сообщений для озвучки в день"]
  },
  {
    name: "Распробовал",
    price: "1200р/месяц",
    features: ["Все функции бесплатного доступа", "1600 сообщений для озвучки в день", "AI Style для озвучки"]
  },
  {
    name: "Мастер",
    price: "5000р/месяц",
    features: [
      "Все функции бесплатного доступа",
      "Никаких лимитов на озвучку",
      "AI Style для озвучки",
      "Бот с выбранным вами ником",
      "Выделенный сервер для стабильной и быстрой работы бота",
      "Возможность разместить бота у других стримеров"
    ]
  },
];