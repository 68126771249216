import * as React from 'react';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    InputLabel,
    Paper,
    Box,
    Popper,
    Fade,
    ClickAwayListener,
    InputBase
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomSelect = React.memo(({ 
    value, 
    options, 
    onChange, 
    disabled, 
    label, 
    placeholder = 'Выберите значение',
    searchPlaceholder = 'Поиск...',
    noOptionsText = 'Ничего не найдено'
}) => {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const anchorRef = useRef(null);

    const handleToggle = () => {
        if (!disabled) {
            setOpen((prevOpen) => !prevOpen);
            if (!open) {
                setSearchQuery('');
            }
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        setSearchQuery('');
    };

    const handleClick = (option) => {
        onChange(option);
        setOpen(false);
        setSearchQuery('');
    };

    const handleSearchChange = (event) => {
        event.stopPropagation();
        setSearchQuery(event.target.value);
    };

    const filteredOptions = options.filter(option => 
        option.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <InputLabel 
                sx={{ 
                    color: '#E0E0E0',
                    marginBottom: '8px'
                }}
            >
                {label}
            </InputLabel>
            <Box
                ref={anchorRef}
                onClick={handleToggle}
                sx={{
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    padding: '8px 12px',
                    border: '1px solid',
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: disabled ? 'rgba(255, 255, 255, 0.12)' : 'transparent',
                    '&:hover': {
                        borderColor: !disabled ? 'primary.main' : 'rgba(255, 255, 255, 0.23)'
                    }
                }}
            >
                <Typography
                    sx={{
                        color: value ? '#E0E0E0' : 'rgba(255, 255, 255, 0.5)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {value || placeholder}
                </Typography>
                <ExpandMoreIcon 
                    sx={{ 
                        color: 'primary.main',
                        transform: open ? 'rotate(180deg)' : 'none',
                        transition: 'transform 0.2s'
                    }} 
                />
            </Box>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
                style={{
                    width: anchorRef.current ? anchorRef.current.clientWidth : 'auto',
                    zIndex: 1300
                }}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8]
                        }
                    },
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            fallbackPlacements: ['top-start']
                        }
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundary: 'clippingParents',
                            altAxis: true,
                            padding: 8
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <Fade {...TransitionProps} timeout={200}>
                                <Paper 
                                    sx={{
                                        mt: 1,
                                        bgcolor: '#1E1E1E',
                                        border: '1px solid rgba(255, 255, 255, 0.12)',
                                        width: '100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 1,
                                            borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                                            bgcolor: '#1E1E1E'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                bgcolor: '#2C2C2C',
                                                borderRadius: 1,
                                                p: '8px 12px'
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <SearchIcon 
                                                sx={{ 
                                                    color: 'rgba(255, 255, 255, 0.5)',
                                                    mr: 1,
                                                    fontSize: '1.2rem'
                                                }} 
                                            />
                                            <InputBase
                                                placeholder={searchPlaceholder}
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                                onClick={(e) => e.stopPropagation()}
                                                sx={{
                                                    color: '#E0E0E0',
                                                    flex: 1,
                                                    '& input': {
                                                        p: 0,
                                                        fontSize: '0.9rem',
                                                        '&::placeholder': {
                                                            color: 'rgba(255, 255, 255, 0.5)',
                                                            opacity: 1
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            maxHeight: '240px',
                                            overflow: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                bgcolor: 'rgba(185, 117, 255, 0.5)',
                                                borderRadius: 1,
                                                '&:hover': {
                                                    bgcolor: 'rgba(185, 117, 255, 0.7)',
                                                }
                                            }
                                        }}
                                    >
                                        {filteredOptions.length === 0 ? (
                                            <Box
                                                sx={{
                                                    p: 2,
                                                    textAlign: 'center',
                                                    color: 'rgba(255, 255, 255, 0.5)'
                                                }}
                                            >
                                                {noOptionsText}
                                            </Box>
                                        ) : (
                                            filteredOptions.map((option) => (
                                                <Box
                                                    key={option}
                                                    onClick={() => handleClick(option)}
                                                    sx={{
                                                        p: '8px 12px',
                                                        cursor: 'pointer',
                                                        bgcolor: value === option ? 'rgba(185, 117, 255, 0.1)' : 'transparent',
                                                        '&:hover': {
                                                            bgcolor: 'rgba(185, 117, 255, 0.1)'
                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#E0E0E0',
                                                            fontSize: '0.9rem'
                                                        }}
                                                    >
                                                        {option}
                                                    </Typography>
                                                </Box>
                                            ))
                                        )}
                                    </Box>
                                </Paper>
                            </Fade>
                        </div>
                    </ClickAwayListener>
                )}
            </Popper>
        </div>
    );
});

CustomSelect.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    noOptionsText: PropTypes.string
};

export default CustomSelect; 