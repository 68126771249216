import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from './routes/dashboard/dashboard';
import DashIndex from './routes/dashboard/pages/dash-index';
import SignIn from './routes/signin';
import PaymentSuccess from './routes/dashboard/pages/payment-success';
import Terms from './routes/terms';
import HomePage from './routes/homePage';
import ErrorPage from './routes/error-page';
import NotImplementedPage from './routes/not-implemented';
import CommandList from './routes/dashboard/pages/command-list';
import AuthRequired from './modules/auth/AuthRequired';
import ChannelForm from './routes/dashboard/pages/channel-form';
import TwitchSignIn from './routes/twitch-signin';
import TwitchProcessAccess from './routes/twitch-process-access';
import TtsSettings from './routes/dashboard/pages/tts-settings';
import TimerList, { timersLoader } from './routes/dashboard/pages/timer-list';

function App() {
    return (
        <>
            <CssBaseline />
            <Routes>
                <Route path="/" element={<HomePage />} errorElement={<ErrorPage />} />
                <Route path="/terms" element={<Terms />} errorElement={<ErrorPage />} />
                <Route path="/sign-in" element={<SignIn />} errorElement={<ErrorPage />} />
                <Route 
                    path="/twitch-sign-in" 
                    element={<TwitchSignIn />} 
                    errorElement={<ErrorPage />} 
                />
                <Route 
                    path="/twitch-auth-callback" 
                    element={<TwitchProcessAccess />} 
                    errorElement={<ErrorPage />} 
                />
                
                <Route element={<AuthRequired />}>
                    <Route path="/dashboard" element={<Dashboard />} errorElement={<ErrorPage />}>
                        <Route index element={<DashIndex />} errorElement={<ErrorPage />} />
                        <Route path="commands" element={<CommandList />} errorElement={<ErrorPage />} />
                        <Route 
                            path="channel-edit" 
                            element={<ChannelForm />} 
                            errorElement={<ErrorPage />} 
                        />
                        <Route 
                            path="tts-settings" 
                            element={<TtsSettings />} 
                            errorElement={<ErrorPage />} 
                        />
                        <Route 
                            path="timers/list" 
                            element={<TimerList />} 
                            loader={timersLoader}
                            errorElement={<ErrorPage />} 
                        />
                        <Route path="payment_success" element={<PaymentSuccess />} />
                    </Route>
                </Route>

                <Route path="*" element={<NotImplementedPage />} errorElement={<ErrorPage />} />
            </Routes>
        </>
    );
}

export default App; 