import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LIGHT_PURPLE = '#B975FF';

const FAQ = () => (
    <Box sx={{ py: { xs: 8, sm: 12 }, px: { xs: 2, sm: 0 } }}>
        <Typography 
            variant="h3" 
            align="center" 
            gutterBottom
            sx={{
                mb: { xs: 4, sm: 6 },
                fontWeight: 'bold',
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                background: 'linear-gradient(45deg, #B975FF, #8A2BE2)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            }}
        >
            Часто задаваемые вопросы
        </Typography>
        <Container maxWidth="md">
            {[
                {
                    question: "Как добавить бота себе на канал?",
                    answer: "Достаточно просто авторизоваться через твитч, и настроить бота в нашей панели управления"
                },
                {
                    question: "Как выбрать голос?",
                    answer: "Стример выбирает стандартный голос для всех зрителей, а зритель может сменить голос сам с помощью команды"
                },
                {
                    question: "Что делать если бот перестал отвечать?",
                    answer: "Проверьте, не забанили ли вы бота, а так же авторизуйтесь в панели управления, и если бот активен, попробуйте деактивировать его и активировать еще раз"
                },
            ].map((item, index) => (
                <Accordion 
                    key={index}
                    sx={{
                        mb: { xs: 1.5, sm: 2 },
                        bgcolor: 'rgba(31, 32, 37, 0.8)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(185, 117, 255, 0.1)',
                        borderRadius: '12px !important',
                        '&:before': {
                            display: 'none',
                        },
                        '&.Mui-expanded': {
                            borderColor: 'rgba(185, 117, 255, 0.3)',
                            mb: { xs: 1.5, sm: 2 },
                        },
                    }}
                >
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{ color: LIGHT_PURPLE }} />}
                        sx={{
                            '&:hover': {
                                bgcolor: 'rgba(185, 117, 255, 0.05)',
                            },
                            minHeight: { xs: '48px', sm: '64px' },
                            '& .MuiAccordionSummary-content': {
                                margin: { xs: '12px 0', sm: '20px 0' },
                            },
                            px: { xs: 2, sm: 3 },
                        }}
                    >
                        <Typography 
                            variant="h6"
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                lineHeight: { xs: 1.4, sm: 1.6 },
                            }}
                        >
                            {item.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: { xs: 2, sm: 3 }, pb: { xs: 2, sm: 3 } }}>
                        <Typography 
                            sx={{ 
                                color: 'text.secondary',
                                fontSize: { xs: '0.9rem', sm: '1rem' },
                                lineHeight: { xs: 1.4, sm: 1.6 },
                            }}
                        >
                            {item.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    </Box>
);

export default FAQ; 