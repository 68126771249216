import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ym_hit } from "../../../utils/yandex";
import Requester from "../../../utils/requester";

const MAX_POLLING_ATTEMPTS = 30;
const POLLING_INTERVAL = 5000;

export default function PaymentSuccess() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState('PENDING');
    const [pollingCount, setPollingCount] = useState(0);
    
    ym_hit('/dashboard/payment_success');
    
    useEffect(() => {
        const orderId = searchParams.get('order_id');
        if (!orderId) {
            navigate('/dashboard');
            return;
        }

        const checkOrderStatus = async () => {
            try {
                const response = await Requester.get(`subscriptions/order/status/?order_id=${orderId}`);
                if (response.ok) {
                    const data = await response.json();
                    setStatus(data.status);
                    
                    if (data.status === 'PENDING' && pollingCount < MAX_POLLING_ATTEMPTS) {
                        setTimeout(() => {
                            setPollingCount(prev => prev + 1);
                        }, POLLING_INTERVAL);
                    }
                }
            } catch (error) {
                console.error('Error checking order status:', error);
                setStatus('FAILED');
            }
        };

        checkOrderStatus();
    }, [pollingCount, navigate, searchParams]);

    const renderContent = () => {
        switch (status) {
            case 'SUCCESS':
                return (
                    <>
                        <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
                        <Typography variant="h4" gutterBottom>
                            Платеж успешно совершен!
                        </Typography>
                        <Typography variant="body1" color="text.secondary" paragraph>
                            Спасибо за покупку! Ваша подписка активирована.
                        </Typography>
                    </>
                );
            case 'FAILED':
                return (
                    <>
                        <ErrorIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
                        <Typography variant="h4" gutterBottom>
                            Ошибка при оплате
                        </Typography>
                        <Typography variant="body1" color="text.secondary" paragraph>
                            К сожалению, произошла ошибка при обработке платежа. Пожалуйста, попробуйте еще раз.
                        </Typography>
                    </>
                );
            case 'PENDING':
                return (
                    <>
                        <CircularProgress size={60} sx={{ mb: 2 }} />
                        <Typography variant="h4" gutterBottom>
                            Обработка платежа
                        </Typography>
                        <Typography variant="body1" color="text.secondary" paragraph>
                            Мы ожидаем подтверждение от платежной системы. Вы можете вернуться в личный кабинет, 
                            мы активируем вашу подписку автоматически после получения подтверждения.
                        </Typography>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', pt: 4 }}>
            <Paper 
                elevation={0}
                sx={{ 
                    p: 4, 
                    textAlign: 'center',
                    backgroundColor: 'background.default',
                    minHeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {renderContent()}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => navigate('/dashboard')}
                    sx={{ mt: 4 }}
                >
                    Вернуться в личный кабинет
                </Button>
            </Paper>
        </Box>
    );
} 