import * as React from 'react';
import {useCallback, useMemo, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
    Alert,
    Autocomplete,
    Breadcrumbs,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select,
    Tooltip,
    IconButton,
    InputAdornment,
    Card,
    CardContent,
    Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {ym_hit} from "../../../utils/yandex";
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import TranslateIcon from '@mui/icons-material/Translate';
import SecurityIcon from '@mui/icons-material/Security';
import { useNotification } from '../../../context/NotificationContext';
import ListSubheader from '@mui/material/ListSubheader';
import { FixedSizeList } from 'react-window';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import CustomSelect from '../../../components/CustomSelect';

const API_URL = "channel/tts_settings/"

const getTtsSettings = async () => {
    const response = await Requester.get(API_URL)
    const response_data = await response.json()
    console.log('TTS Settings Response:', response_data)
    return response_data
}

function get_me_voice(voices, current_voice){
    let me_voice = voices.find(voice => voice[0] === current_voice)
    if (me_voice === undefined){
        return voices[0]
    }
    return me_voice
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    }
};

export default function TtsSettings() {
    const { showNotification } = useNotification();
    const [ttsSettings, setTtsSettings] = React.useState(null);
    const [state, setState] = React.useState({
        loading: false
    });

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getTtsSettings();
                setTtsSettings(data);
            } catch (error) {
                console.error('Error fetching TTS settings:', error);
                showNotification('Ошибка при загрузке настроек', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        };
        fetchData();
    }, [showNotification]);

    const saveChanges = useCallback(async (fieldName, value) => {
        if (state.loading) return;
        
        setState({ ...state, loading: true });
        
        const dataToSend = {
            ...ttsSettings,
            [fieldName]: value
        };

        try {
            const response = await Requester.post(API_URL, dataToSend);
            if (response.status === 200) {
                const data = await getTtsSettings();
                setTtsSettings(data);
                showNotification('Настройки успешно сохранены', {
                    type: 'success',
                    autoHideDuration: 3000
                });
            } else if (response.status === 400) {
                const data = await response.json();
                console.log(data);
                showNotification('Ошибка при сохранении настроек', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        } catch (e) {
            console.log(e);
            showNotification('Ошибка при сохранении настроек', {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setState({ ...state, loading: false });
        }
    }, [state, ttsSettings, showNotification]);

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        saveChanges(name, newValue);
    };

    const handleVoiceChange = (field, value) => {
        saveChanges(field, value);
    };

    ym_hit('/dashboard/tts-settings');

    const renderValue = (value) => {
        return value || 'Выберите голос';
    };

    if (!ttsSettings) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
                    <Typography variant="h6" color="text.secondary">Loading...</Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link 
                            underline="hover" 
                            color="inherit" 
                            to={"/dashboard"} 
                            component={RouterLink}
                            sx={{ 
                                '&:hover': { color: 'primary.main' }
                            }}
                        >
                            Home
                        </Link>
                        <Typography color="primary">Озвучка сообщений</Typography>
                    </Breadcrumbs>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <SettingsVoiceIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                        <Typography variant="h4" fontWeight="500">
                            Настройки TTS
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    {/* Main Settings Card */}
                    <Grid item xs={12}>
                        <Card 
                            elevation={0}
                            sx={{ 
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                                overflow: 'visible'
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {/* Master Toggle */}
                                <Box sx={{ mb: 4 }}>
                                    <FormControlLabel
                                        control={
                                            <Switch 
                                                checked={ttsSettings.tts_enabled}
                                                onChange={handleChange}
                                                name="tts_enabled"
                                                color="primary"
                                                sx={{ mr: 1 }}
                                            />
                                        }
                                        label={
                                            <Typography variant="h6" sx={{ ml: 1 }}>
                                                Озвучка сообщений в чате
                                            </Typography>
                                        }
                                    />
                                </Box>

                                {!ttsSettings.tts_enabled && (
                                    <Alert 
                                        severity="info" 
                                        icon={<InfoIcon />}
                                        sx={{ 
                                            mb: 4,
                                            borderRadius: 2,
                                            bgcolor: 'rgba(145, 70, 255, 0.08)',
                                            border: '1px solid rgba(145, 70, 255, 0.3)',
                                            '& .MuiAlert-icon': {
                                                color: 'primary.main'
                                            }
                                        }}
                                    >
                                        Включите озвучку сообщений чтобы получить ссылку для OBS
                                    </Alert>
                                )}

                                <Grid container spacing={4}>
                                    {/* Voice Settings */}
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={3}>
                                            <Box>
                                                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <SettingsVoiceIcon sx={{ color: 'primary.main' }} />
                                                    Настройки голоса
                                                </Typography>
                                                <Stack spacing={2.5}>
                                                    <CustomSelect
                                                        value={ttsSettings.current_channel_default_voice}
                                                        options={ttsSettings.allowed_default_voices}
                                                        onChange={(value) => handleVoiceChange('current_channel_default_voice', value)}
                                                        disabled={state.loading}
                                                        label="Голос по умолчанию"
                                                        placeholder="Выберите голос"
                                                    />
                                                    <CustomSelect
                                                        value={ttsSettings.tts_additional_voice_for_me_message}
                                                        options={ttsSettings.allowed_default_voices}
                                                        onChange={(value) => handleVoiceChange('tts_additional_voice_for_me_message', value)}
                                                        disabled={state.loading}
                                                        label="Дополнительный голос для !me"
                                                        placeholder="Выберите голос"
                                                    />
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Grid>

                                    {/* Content Settings */}
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={3}>
                                            <Box>
                                                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <SecurityIcon sx={{ color: 'primary.main' }} />
                                                    Фильтрация контента
                                                </Typography>
                                                <Stack spacing={1}>
                                                    <Tooltip title="Фильтрует нецензурную лексику и запрещенные слова">
                                                        <FormControlLabel
                                                            sx={{ ml: 0 }}
                                                            control={
                                                                <Checkbox 
                                                                    color="primary" 
                                                                    name="filter_bad_words_tts"
                                                                    checked={ttsSettings.filter_bad_words_tts}
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                            label="Фильтровать мат и банворды"
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Бот будет автоматически удалять упоминания или ники пользователей из чата">
                                                        <FormControlLabel
                                                            sx={{ ml: 0 }}
                                                            control={
                                                                <Checkbox 
                                                                    color="primary" 
                                                                    name="tts_clean_names"
                                                                    checked={ttsSettings.tts_clean_names}
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                            label="Вырезать имена пользователей"
                                                        />
                                                    </Tooltip>
                                                </Stack>
                                            </Box>

                                            <Box>
                                                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <TranslateIcon sx={{ color: 'primary.main' }} />
                                                    Язык и перевод
                                                </Typography>
                                                <Stack spacing={1}>
                                                    <Tooltip title="Автоматически конвертируем весь текст в русский транслит и используем русские голоса">
                                                        <FormControlLabel
                                                            sx={{ ml: 0 }}
                                                            control={
                                                                <Checkbox 
                                                                    color="primary" 
                                                                    name="only_ru_tts"
                                                                    checked={ttsSettings.only_ru_tts}
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                            label="Всегда озвучивать на русском"
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Сообщения на других языках будут автоматически переведены на русский">
                                                        <FormControlLabel
                                                            sx={{ ml: 0 }}
                                                            control={
                                                                <Checkbox 
                                                                    color="primary" 
                                                                    name="translate_tts"
                                                                    checked={ttsSettings.translate_tts}
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                            label="Переводить сообщения"
                                                        />
                                                    </Tooltip>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                {/* OBS Link Section */}
                                {ttsSettings.tts_enabled && (
                                    <Box sx={{ mt: 5 }}>
                                        <Divider sx={{ mb: 4 }} />
                                        <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <MenuBookIcon sx={{ color: 'primary.main' }} />
                                            Ссылка для OBS
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                value={ttsSettings?.tts_link || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Копировать">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (ttsSettings?.tts_link) {
                                                                            navigator.clipboard.writeText(ttsSettings.tts_link);
                                                                            showNotification('Ссылка скопирована', {
                                                                                type: 'success',
                                                                                autoHideDuration: 3000
                                                                            });
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        color: 'primary.main',
                                                                        '&:hover': {
                                                                            bgcolor: 'primary.dark',
                                                                            color: 'white'
                                                                        }
                                                                    }}
                                                                >
                                                                    <ContentCopyIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        bgcolor: 'background.default',
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'primary.main',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'primary.main',
                                                        }
                                                    }
                                                }}
                                            />
                                            <Button
                                                component={RouterLink}
                                                to="https://docs.jeetbot.cc/text-to-speech/basic-usage"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<MenuBookIcon />}
                                                sx={{
                                                    minWidth: 200,
                                                    height: '56px',
                                                    textTransform: 'none',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                Как добавить в OBS
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
