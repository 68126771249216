import {useState} from "react";
import Requester from "../../utils/requester";
import {Navigate, Outlet} from "react-router-dom";


export default function AuthRequired(){
    const [isAuthorized, setIsAuthorized] = useState(true);
    Requester.validateToken().then((res) => {
        setIsAuthorized(res);
    }).catch((err) => {
        setIsAuthorized(false);
    });
    return isAuthorized ? <Outlet /> : <Navigate to="/" replace={true}/>;
};