import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

const LIGHT_PURPLE = '#B975FF';

export const ScrollLink = ({ to, children, ...props }) => {
    const handleClick = (e) => {
        e.preventDefault();
        const element = document.querySelector(to);
        if (element) {
            const headerOffset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <a 
            href={to} 
            onClick={handleClick} 
            {...props}
            style={{
                WebkitTapHighlightColor: 'transparent',
                ...props.style,
            }}
        >
            {children}
        </a>
    );
};

export const ScrollToTopLink = ({ children, ...props }) => {
    const handleClick = (e) => {
        if (window.location.pathname === '/') {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Box
            component={Link}
            onClick={handleClick}
            {...props}
            sx={{
                WebkitTapHighlightColor: 'transparent',
                ...props.sx,
            }}
        >
            {children}
        </Box>
    );
};

export const MobileNavMenu = ({ items }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (href) => {
        const element = document.querySelector(href);
        if (element) {
            const headerOffset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
        handleClose();
    };

    return (
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
                onClick={handleClick}
                sx={{
                    color: 'text.primary',
                    p: 1,
                    '&:hover': {
                        backgroundColor: 'rgba(185, 117, 255, 0.1)',
                    },
                }}
                aria-label="navigation menu"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    sx: {
                        bgcolor: 'rgba(31, 32, 37, 0.95)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(185, 117, 255, 0.1)',
                        borderRadius: '12px',
                        mt: 1.5,
                        '& .MuiMenuItem-root': {
                            px: 2,
                            py: 1.5,
                            minWidth: 180,
                            '&:hover': {
                                backgroundColor: 'rgba(185, 117, 255, 0.1)',
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {items.map((item) => (
                    <MenuItem 
                        key={item.label} 
                        onClick={() => handleMenuItemClick(item.href)}
                        sx={{
                            gap: 1.5,
                            borderRadius: '8px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'text.secondary',
                                fontSize: '0.95rem',
                                fontWeight: 500,
                                '&:hover': {
                                    color: LIGHT_PURPLE,
                                },
                                transition: 'color 0.2s ease',
                            }}
                        >
                            {item.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}; 