import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fetchSubscriptionPlans, defaultSubscriptionPlans } from "../../data/subscriptionPlans";

const LIGHT_PURPLE = '#B975FF';
const LIGHT_PURPLE_HOVER = 'rgba(185, 117, 255, 0.2)';

const SubscriptionPlans = ({ onTwitchAuth }) => {
    const [plans, setPlans] = useState(defaultSubscriptionPlans);

    useEffect(() => {
        const loadPlans = async () => {
            const data = await fetchSubscriptionPlans();
            if (data.length > 0) {
                setPlans(data);
            }
        };
        loadPlans();
    }, []);

    return (
        <Box sx={{ py: 12 }}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                sx={{
                    mb: 6,
                    fontWeight: 'bold',
                    background: 'linear-gradient(45deg, #B975FF, #8A2BE2)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                Расширенные функции
            </Typography>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
                sx={{
                    maxWidth: 'none',
                    width: '100%',
                    mx: 0,
                    position: 'relative',
                }}
            >
                {plans.map((plan, index) => {
                    const isLastPlan = index === plans.length - 1;
                    return (
                        <Grid 
                            item 
                            xs={12} 
                            sm={6} 
                            md={4} 
                            key={plan.id}
                            sx={{
                                transform: isLastPlan ? 'scale(1.05)' : 'none',
                                '@media (max-width: 900px)': {
                                    transform: 'none',
                                },
                                zIndex: isLastPlan ? 2 : 1,
                                transition: 'all 0.3s ease',
                            }}
                        >
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    bgcolor: isLastPlan ? 'rgba(185, 117, 255, 0.1)' : 'rgba(31, 32, 37, 0.8)',
                                    backdropFilter: 'blur(10px)',
                                    border: '1px solid',
                                    borderColor: isLastPlan ? 'rgba(185, 117, 255, 0.5)' : 'rgba(185, 117, 255, 0.1)',
                                    borderRadius: '24px',
                                    overflow: 'visible',
                                    position: 'relative',
                                    boxShadow: isLastPlan ? '0 8px 32px rgba(185, 117, 255, 0.2)' : 'none',
                                    '&:hover': {
                                        transform: 'translateY(-8px)',
                                        boxShadow: isLastPlan 
                                            ? '0 12px 40px rgba(185, 117, 255, 0.3)'
                                            : '0 8px 32px rgba(185, 117, 255, 0.15)',
                                        borderColor: isLastPlan ? 'rgba(185, 117, 255, 0.8)' : 'rgba(185, 117, 255, 0.3)',
                                    },
                                    transition: 'all 0.3s ease',
                                }}
                            >
                                <CardHeader
                                    title={plan.name}
                                    subheader={
                                        <Box sx={{ mt: 2 }}>
                                            <Typography 
                                                variant="h4" 
                                                sx={{ 
                                                    color: isLastPlan ? LIGHT_PURPLE : 'text.primary',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                    alignItems: 'baseline',
                                                    gap: 1,
                                                }}
                                            >
                                                {plan.price}
                                                <Typography 
                                                    component="span" 
                                                    sx={{ 
                                                        fontSize: '1rem',
                                                        color: 'text.secondary',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    р/месяц
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    }
                                    titleTypographyProps={{
                                        variant: 'h5',
                                        align: 'left',
                                        fontWeight: 'bold',
                                        color: isLastPlan ? LIGHT_PURPLE : 'text.primary',
                                    }}
                                    sx={{
                                        pb: 3,
                                        pt: isLastPlan ? 4 : 3,
                                        px: 3,
                                    }}
                                />
                                <CardContent sx={{ flexGrow: 1, pt: 0, px: 3, pb: 3 }}>
                                    <List sx={{
                                        p: 0,
                                        '& .MuiListItem-root': {
                                            px: 0,
                                            py: 1,
                                        }
                                    }}>
                                        {plan.features.map((feature) => (
                                            <ListItem key={feature}>
                                                <ListItemIcon sx={{ minWidth: 36 }}>
                                                    <CheckCircleIcon sx={{
                                                        color: isLastPlan ? LIGHT_PURPLE : 'rgba(185, 117, 255, 0.5)',
                                                        fontSize: 20,
                                                    }}/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={feature}
                                                    primaryTypographyProps={{
                                                        sx: {
                                                            fontSize: '0.95rem',
                                                            color: isLastPlan ? 'text.primary' : 'text.secondary',
                                                        }
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Button
                                        variant={isLastPlan ? "contained" : "outlined"}
                                        fullWidth
                                        onClick={onTwitchAuth}
                                        sx={{
                                            mt: 4,
                                            py: 1.5,
                                            borderRadius: '12px',
                                            fontSize: '1rem',
                                            backgroundColor: isLastPlan ? LIGHT_PURPLE : 'transparent',
                                            borderColor: LIGHT_PURPLE,
                                            color: isLastPlan ? 'white' : LIGHT_PURPLE,
                                            '&:hover': {
                                                backgroundColor: isLastPlan ? '#9B5FE3' : LIGHT_PURPLE_HOVER,
                                                borderColor: LIGHT_PURPLE,
                                                transform: 'translateY(-2px)',
                                                boxShadow: isLastPlan 
                                                    ? '0 8px 20px rgba(185, 117, 255, 0.3)'
                                                    : 'none',
                                            },
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        {isLastPlan ? 'Выбрать план' : 'Подробнее'}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default SubscriptionPlans; 