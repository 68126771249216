import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Typography, Modal, Grid, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, ToggleButtonGroup, ToggleButton, Link, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useUser } from '../../../context/UserContext';
import { useNotification } from '../../../context/NotificationContext';
import { fetchSubscriptionPlans, defaultSubscriptionPlans } from "../../../data/subscriptionPlans";
import Requester from "../../../utils/requester";
import { getSubscriptionName } from '../utils/subscription-utils';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
};

const SubscriptionModal = ({ open, onClose, currentSubscription }) => {
    const { user, setUser } = useUser();
    const { showNotification } = useNotification();
    const [plans, setPlans] = useState(defaultSubscriptionPlans);
    const [loading, setLoading] = useState(false);
    const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
    const [renewalConfirmOpen, setRenewalConfirmOpen] = useState(false);
    const [downgradeConfirmOpen, setDowngradeConfirmOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('russian');
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const refreshAccountInfo = async () => {
        const accountResponse = await Requester.get('user/account_info/');
        if (accountResponse.ok) {
            const accountData = await accountResponse.json();
            setUser(accountData);
        }
    };

    useEffect(() => {
        const loadPlans = async () => {
            const data = await fetchSubscriptionPlans();
            if (data.length > 0) {
                setPlans(data);
            }
        };
        loadPlans();
    }, []);

    const handlePaymentMethodChange = (event, newPaymentMethod) => {
        if (newPaymentMethod !== null) {
            setPaymentMethod(newPaymentMethod);
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailSubmit = async () => {
        if (!validateEmail(email)) {
            setEmailError('Пожалуйста, введите корректный email');
            return;
        }
        setEmailError('');
        setEmailDialogOpen(false);
        proceedWithPurchase(selectedPlan, email);
    };

    const proceedWithPurchase = async (plan, userEmail = null) => {
        if (paymentMethod === 'europe') {
            window.open('https://jeetbot.cc/boosty', '_blank');
            return;
        }

        if (!currentSubscription.default && plan.price < currentSubscription.subscription_type.price) {
            handleDowngrade(plan);
            return;
        }

        if (!currentSubscription.default &&
            getSubscriptionName(currentSubscription.subscription_type.name) === getSubscriptionName(plan.name)) {
            if (currentSubscription.extendable) {
                setSelectedPlan(plan);
                setCancelConfirmOpen(true);
            } else {
                handleRenewalConfirm();
            }
            return;
        }

        setLoading(true);
        try {
            const paymentData = {
                subscription_type_id: plan.id,
            };
            
            if (userEmail) {
                paymentData.email = userEmail;
            }

            const response = await Requester.post('subscriptions/yoomoney/create_payment/', paymentData);

            const data = await response.json();
            if (response.ok && data.confirmation_url) {
                window.location.href = data.confirmation_url;
            } else if (data.includes('subscription_exists')) {
                await refreshAccountInfo();
                showNotification('Вы уже имеете активную подписку этого типа.', {
                    type: 'warning',
                    autoHideDuration: 5000
                });
                onClose();
            } else {
                showNotification('Ошибка при создании платежа. Пожалуйста, попробуйте еще раз.', {
                    type: 'error',
                    autoHideDuration: 5000
                });
            }
        } catch (error) {
            console.error('Error:', error);
            showNotification('Произошла ошибка. Пожалуйста, попробуйте еще раз.', {
                type: 'error',
                autoHideDuration: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    const handlePurchase = async (plan) => {
        setSelectedPlan(plan);
        if (!user.email) {
            setEmailDialogOpen(true);
            return;
        }
        proceedWithPurchase(plan);
    };

    const handleCancelConfirm = async () => {
        if (selectedPlan) {
            setLoading(true);
            try {
                const response = await Requester.post('subscriptions/unsubscribe/');
                if (response.ok) {
                    await refreshAccountInfo();
                    showNotification('Автопродление подписки успешно отключено. Подписка будет действовать до конца текущего периода.', {
                        type: 'success',
                        autoHideDuration: 5000
                    });
                    onClose();
                } else {
                    showNotification('Ошибка при отмене подписки. Пожалуйста, попробуйте еще раз.', {
                        type: 'error',
                        autoHideDuration: 5000
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                showNotification('Ошибка при отмене подписки. Пожалуйста, попробуйте еще раз.', {
                    type: 'error',
                    autoHideDuration: 5000
                });
            } finally {
                setLoading(false);
            }
        }
        setCancelConfirmOpen(false);
        setSelectedPlan(null);
    };

    const handleCancelDismiss = () => {
        setCancelConfirmOpen(false);
        setSelectedPlan(null);
    };

    const handleRenewalConfirm = async () => {
        setLoading(true);
        try {
            const response = await Requester.post('subscriptions/renew/');
            if (response.ok) {
                await refreshAccountInfo();
                showNotification('Автопродление подписки успешно активировано.', {
                    type: 'success',
                    autoHideDuration: 5000
                });
                onClose();
            } else {
                showNotification('Ошибка при продлении подписки. Пожалуйста, попробуйте еще раз.', {
                    type: 'error',
                    autoHideDuration: 5000
                });
            }
        } catch (error) {
            console.error('Error:', error);
            showNotification('Ошибка при продлении подписки. Пожалуйста, попробуйте еще раз.', {
                type: 'error',
                autoHideDuration: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    const handleRenewalDismiss = () => {
        setRenewalConfirmOpen(false);
        setSelectedPlan(null);
    };

    const handleDowngrade = async (plan) => {
        setSelectedPlan(plan);
        setDowngradeConfirmOpen(true);
    };

    const handleDowngradeConfirm = async () => {
        if (selectedPlan) {
            setLoading(true);
            try {
                const response = await Requester.post('subscriptions/downgrade/', {
                    subscription_type_id: selectedPlan.id
                });
                
                if (response.ok) {
                    await refreshAccountInfo();
                    showNotification('Выбранный тариф будет применен по окончанию текущего периода.', {
                        type: 'success',
                        autoHideDuration: 5000
                    });
                    onClose();
                } else {
                    const errorData = await response.json();
                    showNotification(errorData.message || 'Ошибка при изменении подписки. Пжалуйста, попробуйте еще раз.', {
                        type: 'error',
                        autoHideDuration: 5000
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                showNotification('Произошла ошибка. Пожалуйста, попробуйте еще раз.', {
                    type: 'error',
                    autoHideDuration: 5000
                });
            } finally {
                setLoading(false);
            }
        }
        setDowngradeConfirmOpen(false);
        setSelectedPlan(null);
    };

    const handleDowngradeDismiss = () => {
        setDowngradeConfirmOpen(false);
        setSelectedPlan(null);
    };

    const handleCancelDowngrade = async () => {
        setLoading(true);
        try {
            const response = await Requester.delete('subscriptions/downgrade/');
            if (response.ok) {
                await refreshAccountInfo();
                showNotification('Изменение подписки успешно отменено.', {
                    type: 'success',
                    autoHideDuration: 5000
                });
                onClose();
            } else {
                showNotification('Ошибка при отмене изменения подписки. Пожалуйста, попробуйте еще раз.', {
                    type: 'error',
                    autoHideDuration: 5000
                });
            }
        } catch (error) {
            console.error('Error:', error);
            showNotification('Произошла ошибка. Пожалуйста, попробуйте еще раз.', {
                type: 'error',
                autoHideDuration: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '95%',
                    maxWidth: 1200,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: 2, sm: 4 },
                    maxHeight: '90vh',
                    overflow: 'auto',
                    borderRadius: 2
                }}>
                    <Typography 
                        variant="h4" 
                        component="h2" 
                        gutterBottom
                        sx={{ 
                            fontSize: { xs: '1.5rem', sm: '2rem' },
                            textAlign: { xs: 'center', sm: 'left' }
                        }}
                    >
                        Управление подпиской
                    </Typography>
                    
                    {!currentSubscription.is_boosty && (
                        <Box sx={{ mb: 3 }}>
                            <Typography 
                                variant="h6" 
                                gutterBottom 
                                sx={{ 
                                    textAlign: 'center',
                                    fontSize: { xs: '1.1rem', sm: '1.25rem' }
                                }}
                            >
                                Выберите способ оплаты
                            </Typography>
                            <Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card 
                                        sx={{
                                            cursor: 'pointer',
                                            height: '100%',
                                            border: paymentMethod === 'russian' ? '2px solid' : '1px solid',
                                            borderColor: paymentMethod === 'russian' ? 'primary.main' : 'divider',
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: { xs: 'none', sm: 'translateY(-4px)' },
                                                boxShadow: 3
                                            },
                                            '&:active': {
                                                transform: { xs: 'scale(0.98)', sm: 'translateY(-4px)' }
                                            }
                                        }}
                                        onClick={() => setPaymentMethod('russian')}
                                    >
                                        <CardContent sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Typography variant="h6" gutterBottom>
                                                Карта РФ
                                            </Typography>
                                            <Typography color="text.secondary">
                                                Оплата через ЮMoney
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card 
                                        sx={{
                                            cursor: 'pointer',
                                            height: '100%',
                                            border: paymentMethod === 'europe' ? '2px solid' : '1px solid',
                                            borderColor: paymentMethod === 'europe' ? 'primary.main' : 'divider',
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: { xs: 'none', sm: 'translateY(-4px)' },
                                                boxShadow: 3
                                            },
                                            '&:active': {
                                                transform: { xs: 'scale(0.98)', sm: 'translateY(-4px)' }
                                            }
                                        }}
                                        onClick={() => setPaymentMethod('europe')}
                                    >
                                        <CardContent sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Typography variant="h6" gutterBottom>
                                                Другие страны
                                            </Typography>
                                            <Typography color="text.secondary">
                                                Оплата через Boosty
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    <Grid container spacing={2} justifyContent="center">
                        {plans.map((plan) => (
                            <Grid item xs={12} sm={6} md={4} key={plan.id}>
                                <Card sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: { xs: 'none', sm: 'translateY(-4px)' },
                                        boxShadow: 3
                                    }
                                }}>
                                    <CardContent>
                                        <Typography 
                                            variant="h5" 
                                            component="div" 
                                            gutterBottom
                                            sx={{
                                                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                                textAlign: { xs: 'center', sm: 'left' }
                                            }}
                                        >
                                            {plan.name}
                                        </Typography>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            gap: 1,
                                            justifyContent: { xs: 'center', sm: 'flex-start' }
                                        }}>
                                            {paymentMethod === 'russian' && plan.price !== plan.calculated_price ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography
                                                        variant="h6"
                                                        color="text.secondary"
                                                        sx={{ textDecoration: 'line-through' }}
                                                    >
                                                        {plan.price}₽
                                                    </Typography>
                                                    <Typography variant="h6" color="primary">
                                                        {plan.calculated_price}₽
                                                    </Typography>
                                                    <Tooltip title="Цена снижена на сумму оставшегося времени текущей подписки" arrow placement="top">
                                                        <HelpOutlineIcon
                                                            sx={{
                                                                fontSize: 16,
                                                                color: 'text.secondary',
                                                                cursor: 'help'
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            ) : (
                                                <Typography variant="h6" color="primary">
                                                    {paymentMethod === 'russian' ? `${plan.price}₽` : 'Через Boosty'}
                                                </Typography>
                                            )}
                                            {paymentMethod === 'russian' && (
                                                <Typography variant="h6" color="text.secondary">/Месяц</Typography>
                                            )}
                                        </Box>
                                        <List>
                                            {plan.features.map((feature, featureIndex) => (
                                                <ListItem key={featureIndex} dense>
                                                    <ListItemIcon>
                                                        <CheckCircleIcon color="primary" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={feature} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                    <CardContent>
                                        {currentSubscription.is_boosty ? (
                                            <Typography
                                                variant="body1"
                                                color="text.secondary"
                                                sx={{ textAlign: 'center', mb: 1 }}
                                            >
                                                Управляется на{' '}
                                                <Link
                                                    href="https://jeetbot.cc/boosty"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        color: 'primary.main',
                                                        textDecoration: 'none',
                                                        '&:hover': {
                                                            textDecoration: 'underline'
                                                        }
                                                    }}
                                                >
                                                    Boosty
                                                </Link>
                                            </Typography>
                                        ) : (
                                            <>
                                                {paymentMethod === 'russian' ? (
                                                    <>
                                                        {(!currentSubscription.default &&
                                                            getSubscriptionName(currentSubscription.subscription_type.name) === getSubscriptionName(plan.name) &&
                                                            currentSubscription.extendable) && (
                                                                <Typography
                                                                    variant="caption"
                                                                    color="text.secondary"
                                                                    sx={{ display: 'block', textAlign: 'center', mb: 1, cursor: 'pointer' }}
                                                                    onClick={() => handlePurchase(plan)}
                                                                >
                                                                    Отменить подписку
                                                                </Typography>
                                                            )}
                                                        {currentSubscription.next_type && 
                                                            getSubscriptionName(currentSubscription.next_type.name) === getSubscriptionName(plan.name) && (
                                                                <Typography
                                                                    variant="body2"
                                                                    color="warning.main"
                                                                    sx={{ textAlign: 'center', mb: 1 }}
                                                                >
                                                                    Подписка будет понижена до этого тарифа {currentSubscription.active_until_dt ? `${formatDate(currentSubscription.active_until_dt)}` : 'в конце текущего периода'}
                                                                </Typography>
                                                            )}
                                                        <Button
                                                            variant="contained"
                                                            fullWidth
                                                            color={currentSubscription.next_type && 
                                                                getSubscriptionName(currentSubscription.next_type.name) === getSubscriptionName(plan.name)
                                                                ? 'warning'
                                                                : (!currentSubscription.default &&
                                                                getSubscriptionName(currentSubscription.subscription_type.name) === getSubscriptionName(plan.name))
                                                                ? currentSubscription.extendable ? 'primary' : 'success'
                                                                : currentSubscription.default
                                                                    ? 'primary'
                                                                    : plan.price > currentSubscription.subscription_type.price
                                                                        ? 'primary'
                                                                        : 'secondary'}
                                                            disabled={(!currentSubscription.default &&
                                                                getSubscriptionName(currentSubscription.subscription_type.name) === getSubscriptionName(plan.name) &&
                                                                currentSubscription.extendable)}
                                                            onClick={currentSubscription.next_type && 
                                                                getSubscriptionName(currentSubscription.next_type.name) === getSubscriptionName(plan.name)
                                                                ? handleCancelDowngrade
                                                                : () => handlePurchase(plan)}
                                                        >
                                                            {loading ? 'Processing...' :
                                                                currentSubscription.next_type && 
                                                                getSubscriptionName(currentSubscription.next_type.name) === getSubscriptionName(plan.name)
                                                                ? 'Отменить изменение'
                                                                : (!currentSubscription.default &&
                                                                    getSubscriptionName(currentSubscription.subscription_type.name) === getSubscriptionName(plan.name))
                                                                    ? currentSubscription.extendable ? 'Текущая подписка' : 'Возобновить подписку'
                                                                    : currentSubscription.default
                                                                        ? 'Купить'
                                                                        : plan.price > currentSubscription.subscription_type.price
                                                                            ? 'Повысить'
                                                                            : 'Понизить'}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        color="primary"
                                                        onClick={() => handlePurchase(plan)}
                                                    >
                                                        Купить на Boosty
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Button 
                        onClick={onClose} 
                        sx={{ 
                            mt: 3,
                            width: { xs: '100%', sm: 'auto' }
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            <Dialog
                open={cancelConfirmOpen}
                onClose={handleCancelDismiss}
                aria-labelledby="cancel-dialog-title"
                aria-describedby="cancel-dialog-description"
            >
                <DialogTitle id="cancel-dialog-title">
                    Подтвердите отмену подписки
                </DialogTitle>
                <DialogContent>
                    <Typography id="cancel-dialog-description">
                        Вы уверены, что хотите отменить текущую подписку?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDismiss} color="primary">
                        Отмена
                    </Button>
                    <Button 
                        onClick={handleCancelConfirm} 
                        color="error"
                        disabled={loading}
                    >
                        {loading ? 'Отменяем...' : 'Подтвердить'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={renewalConfirmOpen}
                onClose={handleRenewalDismiss}
                aria-labelledby="renewal-dialog-title"
                aria-describedby="renewal-dialog-description"
            >
                <DialogTitle id="renewal-dialog-title">
                    Подтвердите продление подписки
                </DialogTitle>
                <DialogContent>
                    <Typography id="renewal-dialog-description">
                        Вы уверены, что хотите продлить текущую подписку?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRenewalDismiss} color="primary">
                        Отмена
                    </Button>
                    <Button 
                        onClick={handleRenewalConfirm} 
                        color="success"
                        disabled={loading}
                    >
                        {loading ? 'Продлеваем...' : 'Подтвердить'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={downgradeConfirmOpen}
                onClose={handleDowngradeDismiss}
                aria-labelledby="downgrade-dialog-title"
                aria-describedby="downgrade-dialog-description"
            >
                <DialogTitle id="downgrade-dialog-title">
                    Подтвердите изменение подписки
                </DialogTitle>
                <DialogContent>
                    <Typography id="downgrade-dialog-description">
                        Вы уверены, что хотите понизить вашу подписку? Это изменение будет применено по окончанию текущего периода.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDowngradeDismiss} color="primary">
                        Отмена
                    </Button>
                    <Button 
                        onClick={handleDowngradeConfirm} 
                        color="warning"
                        disabled={loading}
                    >
                        {loading ? 'Понижаем...' : 'Подтвердить'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={emailDialogOpen}
                onClose={() => setEmailDialogOpen(false)}
                aria-labelledby="email-dialog-title"
                aria-describedby="email-dialog-description"
            >
                <DialogTitle id="email-dialog-title">
                    Введите email
                </DialogTitle>
                <DialogContent>
                    <Typography id="email-dialog-description" sx={{ mb: 2 }}>
                        Пожалуйста, укажите email для отправки чека об оплате.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEmailDialogOpen(false)} color="primary">
                        Отмена
                    </Button>
                    <Button 
                        onClick={handleEmailSubmit}
                        color="primary"
                        disabled={loading}
                    >
                        {loading ? 'Подождите...' : 'Продолжить'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SubscriptionModal; 