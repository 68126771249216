import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useUser } from '../../../context/UserContext';
import SubscriptionInfo from '../components/SubscriptionInfo';
import SubscriptionModal from '../components/SubscriptionModal';

export default function DashIndex() {
    const { user } = useUser();
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    if (!user) {
        return <Typography>Подгружаем данные...</Typography>;
    }

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', pt: 4 }}>
            <Typography variant="h4" gutterBottom>Welcome, {user.name}!</Typography>
            <SubscriptionInfo
                currentSubscription={user.current_subscription}
                onUpgrade={handleOpenModal}
            />
            <SubscriptionModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                currentSubscription={user.current_subscription}
            />
        </Box>
    );
}
