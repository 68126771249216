import React from 'react';
import { Box, Button, Card, Typography, Chip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { getSubscriptionName, getSubscriptionColor, formatDate, getDaysUntil } from '../utils/subscription-utils';

const SubscriptionInfo = ({ currentSubscription, onUpgrade }) => {
    const showDateInfo = !currentSubscription.default && (currentSubscription.active_until_dt || currentSubscription.is_boosty);
    const isExtendable = currentSubscription.extendable;

    return (
        <Card sx={{ p: { xs: 1.5, sm: 2 }, mb: 2, position: 'relative', overflow: 'visible' }}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between', 
                alignItems: { xs: 'center', sm: 'flex-start' },
                gap: { xs: 2, sm: 0 }
            }}>
                <Box sx={{ 
                    flex: 1,
                    textAlign: { xs: 'center', sm: 'left' }
                }}>
                    <Typography variant="h6" gutterBottom>TTS Limit</Typography>
                    <Typography variant="h4">
                        {currentSubscription.left_tts_limit ?? 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        сообщений осталось сегодня
                    </Typography>
                </Box>
                <Box sx={{ 
                    flex: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: { xs: 'center', sm: 'flex-end' },
                    position: { xs: 'relative', sm: 'absolute' },
                    top: { xs: 0, sm: -15 },
                    right: { xs: 0, sm: 16 },
                    width: { xs: '100%', sm: 'auto' }
                }}>
                    <Box sx={{ mb: 2 }}>
                        <Chip
                            label={getSubscriptionName(currentSubscription.subscription_type.name) || 'Free'}
                            color={getSubscriptionColor(currentSubscription.subscription_type.name)}
                            icon={<StarIcon />}
                        />
                    </Box>
                    <Button 
                        variant="contained" 
                        onClick={onUpgrade} 
                        sx={{ 
                            width: { xs: '100%', sm: 'auto' },
                            minWidth: { sm: '200px' }
                        }}
                    >
                        {!currentSubscription.subscription_type.name ? 'Купить подписку' : 'Управление подпиской'}
                    </Button>
                    {showDateInfo && (
                        <Box 
                            sx={{ 
                                mt: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: { xs: 'center', sm: 'flex-end' },
                                gap: 1,
                                position: 'relative',
                                width: '100%'
                            }}
                        >
                            {!currentSubscription.is_boosty && (
                                <Box 
                                    sx={{ 
                                        width: 6, 
                                        height: 6, 
                                        borderRadius: '50%',
                                        backgroundColor: getDaysUntil(currentSubscription.active_until_dt) <= 7 ? 'warning.main' : 'success.main',
                                        opacity: 0.7,
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    position: 'relative',
                                    '&:hover .payment-details': {
                                        opacity: 1,
                                        visibility: 'visible',
                                    }
                                }}
                            >
                                <Typography 
                                    variant="caption" 
                                    color="text.secondary"
                                    sx={{ 
                                        fontWeight: !currentSubscription.is_boosty && getDaysUntil(currentSubscription.active_until_dt) <= 7 ? 500 : 400,
                                        cursor: isExtendable && currentSubscription.payment_data ? 'pointer' : 'default',
                                        borderBottom: isExtendable && currentSubscription.payment_data ? '1px dashed rgba(0, 0, 0, 0.2)' : 'none',
                                    }}
                                >
                                    {currentSubscription.is_boosty ? (
                                        'Управляется на Бусти'
                                    ) : (
                                        <>
                                            {isExtendable ? 'Автопродление ' : 'Активна до '} 
                                            {formatDate(currentSubscription.active_until_dt)}
                                        </>
                                    )}
                                </Typography>
                                {!currentSubscription.is_boosty && isExtendable && currentSubscription.payment_data && (
                                    <Box 
                                        className="payment-details"
                                        sx={{ 
                                            position: 'absolute',
                                            top: 'calc(100% + 5px)',
                                            right: 0,
                                            backgroundColor: 'grey.900',
                                            color: 'common.white',
                                            borderRadius: '4px',
                                            px: 1.5,
                                            py: 1,
                                            opacity: 0,
                                            visibility: 'hidden',
                                            transition: 'all 0.2s ease-in-out',
                                            zIndex: 1,
                                            whiteSpace: 'nowrap',
                                            fontSize: '0.75rem',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: -4,
                                                right: 10,
                                                width: 8,
                                                height: 8,
                                                backgroundColor: 'grey.900',
                                                transform: 'rotate(45deg)',
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                            <Typography 
                                                variant="caption"
                                                sx={{ 
                                                    color: 'grey.400',
                                                }}
                                            >
                                                Спишем с 
                                            </Typography>
                                            <Typography 
                                                variant="caption"
                                                sx={{ 
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {currentSubscription.payment_data}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Card>
    );
};

export default SubscriptionInfo; 