import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Popover, Box, Typography, List, ListItem, ListItemText, CircularProgress, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import Requester from '../utils/requester';

const NotificationsButton = () => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleNotificationHover = async (notification) => {
        if (!notification.is_read) {
            try {
                await Requester.post(`notifications/${notification.id}/`);
                // Update the local state to mark this notification as read
                setNotifications(prevNotifications =>
                    prevNotifications.map(notif =>
                        notif.id === notification.id ? { ...notif, is_read: true } : notif
                    )
                );
                setUnreadCount(prev => Math.max(0, prev - 1));
            } catch (error) {
                console.error('Error marking notification as read:', error);
            }
        }
    };

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const response = await Requester.get('notifications/');
            const data = await response.json();
            const notificationsData = Array.isArray(data) ? data : data?.data || [];
            setNotifications(notificationsData);
            setUnreadCount(notificationsData.filter(notif => !notif.is_read).length);
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setNotifications([]);
            setUnreadCount(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
        // Poll for new notifications every 10 minutes
        const interval = setInterval(fetchNotifications, 600000);
        return () => clearInterval(interval);
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const handleDeleteNotification = async (event, notificationId) => {
        event.stopPropagation(); // Prevent triggering hover effect
        try {
            await Requester.delete(`notifications/${notificationId}/`);
            setNotifications(prevNotifications =>
                prevNotifications.filter(notif => notif.id !== notificationId)
            );
            // Update unread count if we're deleting an unread notification
            setUnreadCount(prev => {
                const deletedNotification = notifications.find(n => n.id === notificationId);
                return deletedNotification && !deletedNotification.is_read ? prev - 1 : prev;
            });
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const handleDeleteAllNotifications = async () => {
        try {
            await Requester.delete('notifications/delete-all/');
            setNotifications([]);
            setUnreadCount(0);
            handleClose(); // Close the popover after successful deletion
        } catch (error) {
            console.error('Error deleting all notifications:', error);
        }
    };

    return (
        <>
            <IconButton
                size="large"
                color="inherit"
                onClick={handleClick}
                sx={{
                    mr: 1,
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}
            >
                <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: {
                        width: '400px',
                        maxHeight: '500px',
                        overflow: 'auto',
                    }
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">
                            Уведомления
                        </Typography>
                        {notifications.length > 0 && (
                            <Button
                                size="small"
                                onClick={handleDeleteAllNotifications}
                                color="error"
                                variant="text"
                                sx={{ minWidth: 'auto' }}
                            >
                                Удалить все
                            </Button>
                        )}
                    </Box>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : notifications.length > 0 ? (
                        <List>
                            {notifications.map((notification) => (
                                <ListItem 
                                    key={notification.id}
                                    onMouseEnter={() => handleNotificationHover(notification)}
                                    sx={{
                                        backgroundColor: notification.is_read ? 'transparent' : 'rgba(185, 117, 255, 0.1)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(185, 117, 255, 0.05)',
                                        },
                                        position: 'relative',
                                    }}
                                >
                                    <ListItemText
                                        primary={notification.title}
                                        secondary={
                                            <>
                                                <Typography 
                                                    component="span" 
                                                    variant="body2" 
                                                    color="text.primary"
                                                    sx={{ whiteSpace: 'pre-line' }}
                                                >
                                                    {notification.message}
                                                </Typography>
                                                <br />
                                                <Typography component="span" variant="caption" color="text.secondary">
                                                    {formatDate(notification.created_at)}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleDeleteNotification(e, notification.id)}
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            opacity: 0.6,
                                            '&:hover': {
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
                            Нет уведомлений
                        </Typography>
                    )}
                </Box>
            </Popover>
        </>
    );
};

export default NotificationsButton; 