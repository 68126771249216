export const getSubscriptionName = (level) => {
    if (level.includes('Решил попробовать')) {
        return 'Решил попробовать';
    }
    if (level.includes('Не распробовал')) {
        return 'Не распробовал';
    }
    if (level.includes('Распробовал')) {
        return 'Распробовал';
    }
    if (level.includes('Мастер')) {
        return 'Мастер';
    }
    return 'Бесплатная';
};

export const getSubscriptionColor = (level) => {
    if (level.includes('Решил попробовать') || level.includes('Не распробовал')) {
        return 'primary';
    }
    if (level.includes('Распробовал')) {
        return 'warning';
    }
    if (level.includes('Мастер')) {
        return 'error';
    }
    return 'default';
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(date);
};

export const getDaysUntil = (dateString) => {
    const today = new Date();
    const futureDate = new Date(dateString);
    const diffTime = futureDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}; 