import * as React from "react";
import {useEffect} from "react";
import Requester from "../utils/requester";
import '../index.css';
import {TWITCH_REDIRECT_URI} from "../constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";

export default function TwitchSignIn() {
    useEffect(() => {
        Requester.post('twitch/get_twitch_auth_url/', {
            redirect_url: TWITCH_REDIRECT_URI,
        }).then((response) => {
            if (response.status !== 200) {
                localStorage.setItem('auth_error', 'Ошибка при авторизации через Twitch');
                window.location.href = '/';
                return;
            }
            response.json().then(data => {
                if ([400, 401].includes(response.status)) {
                    console.log(data);
                    localStorage.setItem('auth_error', 'Ошибка при авторизации через Twitch');
                    window.location.href = '/';
                    return;
                }
                window.location.href = data.url;
            });
        }).catch((error) => {
            console.log(error);
            localStorage.setItem('auth_error', 'Ошибка при авторизации через Twitch');
            window.location.href = '/';
        });
    }, []);

    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}
                  sx={{minHeight: '100vh'}}>
                <Grid item>
                    <Typography variant="h4" component="div" gutterBottom>
                        Redirecting to Twitch...
                    </Typography>
                </Grid>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        </>
    );
}
