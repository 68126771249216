import React, { createContext, useContext, useState, useEffect } from 'react';
import { Snackbar, Alert, IconButton, LinearProgress, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionGroup } from 'react-transition-group';
import Slide from '@mui/material/Slide';

const NotificationContext = createContext(null);

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
};

const NotificationContent = ({ notification, onClose }) => {
    const [progress, setProgress] = useState(100);
    const duration = notification.autoHideDuration;

    useEffect(() => {
        if (!duration) return;

        const startTime = Date.now();
        const timer = setInterval(() => {
            const elapsed = Date.now() - startTime;
            const remaining = Math.max(0, 100 - (elapsed / duration) * 100);
            setProgress(remaining);

            if (remaining === 0) {
                clearInterval(timer);
            }
        }, 10);

        return () => clearInterval(timer);
    }, [duration]);

    return (
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
            {duration && (
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '2px',
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgba(255,255,255,0.5)',
                        },
                    }}
                />
            )}
            <Alert
                severity={notification.type}
                variant="filled"
                sx={{ 
                    width: '100%',
                    maxWidth: '400px',
                    pt: duration ? '12px' : '6px',
                    '& .MuiAlert-message': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-word',
                    },
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                        sx={{ alignSelf: 'flex-start', mt: 0.5 }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                {notification.message}
            </Alert>
        </Box>
    );
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const showNotification = (message, options = {}) => {
        const id = Date.now();
        const notification = {
            id,
            message,
            type: options.type || 'info',
            autoHideDuration: options.autoHideDuration || null,
            ...options
        };
        
        setNotifications(prev => [...prev, notification]);

        if (notification.autoHideDuration) {
            setTimeout(() => {
                handleClose(id);
            }, notification.autoHideDuration);
        }
    };

    const handleClose = (id) => {
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 24,
                    right: 24,
                    zIndex: 2000,
                }}
            >
                <TransitionGroup>
                    {notifications.map((notification, index) => (
                        <Slide
                            key={notification.id}
                            direction="left"
                            mountOnEnter
                            unmountOnExit
                        >
                            <Box
                                sx={{
                                    mb: index < notifications.length - 1 ? 2 : 0,
                                }}
                            >
                                <NotificationContent 
                                    notification={notification} 
                                    onClose={() => handleClose(notification.id)}
                                />
                            </Box>
                        </Slide>
                    ))}
                </TransitionGroup>
            </Box>
        </NotificationContext.Provider>
    );
}; 