import React, { useEffect, useState, useRef } from 'react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import Requester from "../../utils/requester";

const LIGHT_PURPLE = '#B975FF';
const LIGHT_PURPLE_HOVER = 'rgba(185, 117, 255, 0.2)';
const FALLBACK_CHANNELS = '3500+';
const FALLBACK_MESSAGES = 100; // millions
const ANIMATION_DURATION = 2000; // 2 seconds
const INITIAL_MESSAGES = 1; // millions

const HeroContent = ({ onTwitchAuth }) => {
    const [displayCount, setDisplayCount] = useState('100+');
    const [messagesCount, setMessagesCount] = useState(`${INITIAL_MESSAGES}млн`);
    const animationFrameRef = useRef();
    const messagesAnimationFrameRef = useRef();
    const startTimeRef = useRef();
    const messagesStartTimeRef = useRef();

    const animateValue = (startValue, endValue, onProgress, timeRef, frameRef) => {
        const animate = (currentTime) => {
            if (!timeRef.current) {
                timeRef.current = currentTime;
            }

            const elapsedTime = currentTime - timeRef.current;
            const progress = Math.min(elapsedTime / ANIMATION_DURATION, 1);
            
            // Easing function for smoother animation
            const easeOutQuart = 1 - Math.pow(1 - progress, 4);
            
            const currentValue = Math.floor(startValue + (endValue - startValue) * easeOutQuart);
            onProgress(currentValue);

            if (progress < 1) {
                frameRef.current = requestAnimationFrame(animate);
            }
        };

        frameRef.current = requestAnimationFrame(animate);
    };

    const animateChannels = (startCount, endCount) => {
        animateValue(
            startCount,
            endCount,
            (value) => setDisplayCount(value.toLocaleString()),
            startTimeRef,
            animationFrameRef
        );
    };

    const animateMessages = (startMillions, endMillions) => {
        animateValue(
            startMillions,
            endMillions,
            (value) => setMessagesCount(`${value}млн`),
            messagesStartTimeRef,
            messagesAnimationFrameRef
        );
    };

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await Requester.get('stats/public/');
                if (response.ok) {
                    const data = await response.json();
                    animateChannels(100, data.channels_count);
                    if (data.messages_processed) {
                        const millionsProcessed = Math.floor(data.messages_processed / 1000000);
                        animateMessages(INITIAL_MESSAGES, millionsProcessed);
                    } else {
                        animateMessages(INITIAL_MESSAGES, FALLBACK_MESSAGES);
                    }
                } else {
                    setDisplayCount(FALLBACK_CHANNELS);
                    animateMessages(INITIAL_MESSAGES, FALLBACK_MESSAGES);
                }
            } catch (error) {
                console.error('Error fetching stats:', error);
                setDisplayCount(FALLBACK_CHANNELS);
                animateMessages(INITIAL_MESSAGES, FALLBACK_MESSAGES);
            }
        };

        fetchStats();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (messagesAnimationFrameRef.current) {
                cancelAnimationFrame(messagesAnimationFrameRef.current);
            }
        };
    }, []);

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                pt: { xs: 8, sm: 12 },
                pb: { xs: 6, sm: 8 },
                textAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at 50% 50%, rgba(185, 117, 255, 0.15), rgba(185, 117, 255, 0) 70%)',
                    animation: 'pulse 8s ease-in-out infinite',
                    '@keyframes pulse': {
                        '0%': { opacity: 0.5 },
                        '50%': { opacity: 0.8 },
                        '100%': { opacity: 0.5 },
                    },
                }}
            />
            <Container maxWidth="lg">
                <Box sx={{ position: 'relative' }}>
                    <img
                        src="/logo_nobg.png"
                        alt="TwitchBot Logo"
                        style={{
                            width: '200px',
                            height: '200px',
                            marginBottom: '1.5rem',
                            animation: 'float 6s ease-in-out infinite',
                            '@media (min-width: 600px)': {
                                width: '250px',
                                height: '250px',
                                marginBottom: '2rem',
                            },
                        }}
                    />
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            mb: 3,
                            fontSize: { xs: '2.5rem', md: '3.5rem' },
                            background: 'linear-gradient(45deg, #B975FF, #8A2BE2)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Сделайте стримы интерактивнее
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="text.secondary"
                        paragraph
                        sx={{
                            maxWidth: '800px',
                            mx: 'auto',
                            mb: 5,
                            fontSize: { xs: '1.1rem', md: '1.3rem' },
                            lineHeight: 1.6,
                        }}
                    >
                        Мощный бот для Twitch с функциями озвучки сообщений, автоматического перевода и множеством других возможностей для улучшения взаимодействия со зрителями
                    </Typography>
                    <Box sx={{ 
                        display: 'flex', 
                        gap: { xs: 2, sm: 3 }, 
                        justifyContent: 'center', 
                        flexWrap: 'wrap',
                        px: { xs: 2, sm: 0 }
                    }}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={onTwitchAuth}
                            className="landingButton"
                            sx={{
                                backgroundColor: LIGHT_PURPLE,
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#9B5FE3',
                                    transform: 'translateY(-2px)',
                                    boxShadow: `0 8px 20px ${LIGHT_PURPLE_HOVER}`,
                                },
                                transition: 'all 0.3s ease',
                                fontSize: { xs: '1.1rem', sm: '1.3rem' },
                                py: { xs: 1.8, sm: 2 },
                                px: { xs: 4, sm: 5 },
                                borderRadius: '30px',
                                width: { xs: '100%', sm: 'auto' },
                                maxWidth: { xs: '300px', sm: 'none' },
                                fontWeight: 'bold',
                                textTransform: 'none',
                                boxShadow: `0 4px 15px rgba(185, 117, 255, 0.4), 0 0 20px rgba(185, 117, 255, 0.2)`,
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    borderRadius: '30px',
                                    padding: '2px',
                                    background: 'linear-gradient(45deg, rgba(185, 117, 255, 0.8), rgba(138, 43, 226, 0.8))',
                                    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                    WebkitMaskComposite: 'destination-out',
                                    maskComposite: 'exclude',
                                },
                            }}
                        >
                            Начать использовать бесплатно
                        </Button>
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="https://docs.jeetbot.cc/"
                            sx={{
                                borderColor: 'rgba(185, 117, 255, 0.3)',
                                color: 'text.secondary',
                                '&:hover': {
                                    borderColor: LIGHT_PURPLE,
                                    backgroundColor: 'rgba(185, 117, 255, 0.08)',
                                    transform: 'translateY(-2px)',
                                },
                                transition: 'all 0.3s ease',
                                fontSize: { xs: '1rem', sm: '1.2rem' },
                                py: { xs: 1.5, sm: 1.8 },
                                px: { xs: 3, sm: 4 },
                                borderRadius: '30px',
                                width: { xs: '100%', sm: 'auto' },
                                maxWidth: { xs: '300px', sm: 'none' },
                                textTransform: 'none',
                            }}
                        >
                            Узнать больше
                        </Button>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        gap: { xs: 4, sm: 6, md: 8 }, 
                        mt: { xs: 6, sm: 8 },
                        flexWrap: 'wrap',
                        px: { xs: 2, sm: 0 }
                    }}>
                        {[
                            { number: displayCount, label: 'Активных стримеров', showPlus: false },
                            { number: '300k', label: 'Озвученных сообщений', showPlus: true },
                            { number: messagesCount, label: 'Обработанных сообщений', showPlus: true },
                        ].map((stat) => (
                            <Box key={stat.label} sx={{ 
                                textAlign: 'center',
                                minWidth: { xs: '140px', sm: 'auto' }
                            }}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: LIGHT_PURPLE,
                                        mb: 1,
                                        fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
                                    }}
                                >
                                    {stat.number}{stat.showPlus && '+'}
                                </Typography>
                                <Typography 
                                    variant="subtitle1" 
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.9rem', sm: '1rem' },
                                        maxWidth: { xs: '120px', sm: 'none' },
                                        mx: 'auto'
                                    }}
                                >
                                    {stat.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

const Hero = (props) => {
    const location = useLocation();
    // Force remount of HeroContent when location changes
    return <HeroContent key={location.key} {...props} />;
};

export default Hero; 